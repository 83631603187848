import { Grid } from '@mui/material';
import StatusContaReceberField from '../../Components/generic/StatusContaReceberField';
import ListaContaReceber from './ListaContaReceber';
import dayjs from 'dayjs';
import DatePickerGeneric from '../../Components/generic/DatePickerGeneric';
import { useState } from 'react';
import { usePageStorage } from '../../helper';
import { DUPR_STATUS_EM_ABERTO } from '../../constants';

const FinanceiroPage: React.FC = () => {
  const [dataInicio, setDataInicio] = useState(dayjs().startOf('month'));
  const [dataFim, setDataFim] = useState(dayjs().endOf('month'));
  const [codigoStatus, setCodigoStatus] = usePageStorage<string>(
    'FINANCEIRO PAGE',
    'codigoStatus',
    DUPR_STATUS_EM_ABERTO
  );

  return (
    <Grid container spacing={2} style={{ textAlign: 'center' }}>

      <Grid item xs={12}>
        <StatusContaReceberField
          status={codigoStatus}
          setStatus={(value) => {
            setCodigoStatus(value);
          }}
        />
      </Grid>

      {codigoStatus !== DUPR_STATUS_EM_ABERTO && (
        <>
          <Grid item xs={6}>
            <DatePickerGeneric
              label={'Vencimento de'}
              value={dataInicio}
              setValue={(value) => setDataInicio(value)}
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <DatePickerGeneric
              label={'Vencimento até'}
              value={dataFim}
              setValue={(value) => setDataFim(value)}
              fullWidth
            />
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <ListaContaReceber codigoStatus={codigoStatus} dataInicio={dataInicio} dataFim={dataFim} />
      </Grid>
    </Grid>
  );
};

export default FinanceiroPage;
