import * as React from 'react';
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import { DUPR_STATUS_EM_ABERTO, DUPR_STATUS_PAGO, DUPR_STATUS_TODOS } from '../../constants';

type Props = {
  setStatus: (p: string) => void;
  status: string;
};

const StatusContaReceberField: React.FC<Props> = ({ setStatus, status }) => {

  const arrayStatus = [
    { codigo: DUPR_STATUS_EM_ABERTO, descricao: 'PENDENTES' },
    { codigo: DUPR_STATUS_PAGO, descricao: 'PAGOS' },
    { codigo: DUPR_STATUS_TODOS, descricao: 'TODOS' }
  ];

  const selectedStatus = arrayStatus.find((item) => item.codigo === status);

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="custom-select-label">Status</InputLabel>
      <Select
        labelId="custom-select-label"
        id="custom-select"
        value={selectedStatus ? selectedStatus.descricao : ''}
        onChange={(event) => {
          const newVal = arrayStatus.find((item) => item.descricao === event.target.value);
          if (newVal) {
            setStatus(newVal.codigo);
          }
        }}
        label="Status"
        input={<OutlinedInput label="Status" />}
      >
        {arrayStatus.map((item, i) => (
          <MenuItem key={'menuitem' + i} value={item.descricao}>
            {item.descricao}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default StatusContaReceberField;
