import { useEffect, useState } from 'react';
import { Card, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CardTotalizadores from '../../Components/generic/CardTotalizadores';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import UpcomingIcon from '@mui/icons-material/Upcoming';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import { formatarDinheiro, mostrarToast } from '../../helper';
import { useSessao } from '../../redux/sessao/sessaoHooks';
import apiContaReceber from '../../api/apiContaReceber';
import apiPedido from '../../api/apiPedido';
import { EdNot } from '../../Entity/TypeormTypes';

const CardsFinanceiro: React.FC = () => {
  const navigate = useNavigate();
  const [sessao] = useSessao();
  const [totalDebitoVencido, setTotalDebitoVencido] = useState(0);
  const [totalDebitoAVencer, setTotalDebitoAVencer] = useState(0);
  const [compras, setCompras] = useState(0);
  const [loading, setLoading] = useState(false);

  const codigoCliente = sessao?.clienteUsuario?.codigoCliente;
  const codigoEmpresa = sessao?.empresa?.codigoEmpresa;
  const cfgEmpresaWeb = sessao?.empresa;

  const getDebitos = async () => {
    try {
      if (!codigoCliente) return;
      setLoading(true);

      const totais = await apiContaReceber.getTotalDebitosEdcliente();
      const totalDebitosAVencer = totais.totalDebitosAVencer;
      const totalDebitosVencidos = totais.totalDebitosVencidos;

      setTotalDebitoVencido(totalDebitosVencidos);
      setTotalDebitoAVencer(totalDebitosAVencer);
    } catch (error) {
      console.error(error);
      mostrarToast(`Falha ao buscar débitos: ${error}`, 'error');
      return;
    } finally {
      setLoading(false);
    }
  };

  const getCompras = async () => {
    try {
      if (!codigoCliente) return;
      setLoading(true);

      const qntCompras = await apiPedido.count({
        where: { codigoCliente: codigoCliente, cancelado: EdNot('S'), codigoEmpresa: codigoEmpresa }
      });

      if (qntCompras) {
        setCompras(qntCompras);
      }
    } catch (error) {
      console.error(error);
      mostrarToast(`Falha ao buscar débitos: ${error}`, 'error');
      return;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDebitos();
    getCompras();
  }, []);

  return (
    <Grid item container>

      <Grid
        item
        xs={12}
        sx={{
          marginTop: { xs: '0', md: '30px' },
          padding: '10px',
          borderRadius: '12px'
        }}
      >
        <Card
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '50px',
            padding: '10px'
          }}
        >
          <Typography fontSize={20} style={{ textAlign: 'center' }}>
            Meus débitos
          </Typography>
        </Card>
      </Grid>

      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <CardTotalizadores
          titulo={'Total débitos vencidos'}
          value={
            totalDebitoVencido !== 0 ? `RS ${formatarDinheiro(totalDebitoVencido)}` : 'RS 0,00'
          }
          icon={<AccessTimeIcon sx={{ fontSize: '40px' }} />}
          backgroundColor={'#d32f2f'}
          color={'whitesmoke'}
          telaDeNavegacao="financeiro"
          loading={loading}
        />


        {cfgEmpresaWeb?.isShowDebitosVencerPaginaInicial && (
          <CardTotalizadores
            titulo={'Total débitos à vencer'}
            value={totalDebitoAVencer ? `RS ${formatarDinheiro(totalDebitoAVencer)}` : 'RS 0,00'}
            icon={<UpcomingIcon sx={{ fontSize: '40px' }} />}
            backgroundColor={'#FF8C00'}
            color={'whitesmoke'}
            telaDeNavegacao="financeiro"
            loading={loading}
          />
        )}



        <CardTotalizadores
          titulo={'Minhas compras'}
          value={compras.toString() || '0'}
          icon={<LocalMallIcon sx={{ fontSize: '40px' }} />}
          backgroundColor={'#006400'}
          color={'whitesmoke'}
          telaDeNavegacao="pedidos"
          loading={loading}
        />
      </div>


    </Grid>
  );
};

export default CardsFinanceiro;
