import { Divider, Grid, IconButton, LinearProgress, Paper } from '@mui/material';
import { ContaReceber } from '../../Entity/ContaReceber';
import { useEffect, useState } from 'react';
import { EdBetween, EdNot, EdWhereObject, FindManyReq } from '../../Entity/TypeormTypes';
import {
  copyToClipboard,
  formatarDinheiro,
  getTipoStatus,
  parseData,
  useUpdateEffect
} from '../../helper';
import { Dayjs } from 'dayjs';
import contaReceberApi from '../../api/apiContaReceber';
import { toast } from 'react-toastify';
import { DataGrid, GridColDef, ptBR } from '@mui/x-data-grid';
import { DUPR_STATUS_EM_ABERTO, DUPR_STATUS_PAGO, DUPR_STATUS_TODOS } from '../../constants';
import { DadosListagemContasReceberCliente } from '../../types/ContaReceberTypes';

import PixIcon from '@mui/icons-material/Pix';
import ModalQrCode from './ModalQrCode';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import { useSessao } from '../../redux/sessao/sessaoHooks';

import Typography from '@mui/material/Typography';
import ButtonGeneric from '../../Components/generic/ButtonGeneric';

type Props = {
  codigoStatus: string | null;
  dataInicio: Dayjs;
  dataFim: Dayjs;
  style?: React.CSSProperties;
};

const LINHAS_POR_PAGINA = 10;

const ListaContaReceber: React.FC<Props> = (props) => {
  const { codigoStatus, dataInicio, dataFim } = props;

  const [query, setQuery] = useState<FindManyReq<ContaReceber>>({});
  const [contasReceber, setContasReceber] = useState<DadosListagemContasReceberCliente[]>([]);
  const [totalLinhas, setTotalLinhas] = useState(0);
  const [pagina, setPagina] = useState(0);
  const [loading, setLoading] = useState(true);
  const [openModalQrCode, setOpenModalQrCode] = useState(true);
  const [sessao] = useSessao();
  const [dadosSelecionados, setDadosSelecionados] =
    useState<DadosListagemContasReceberCliente | null>(null);

  useEffect(() => {
    buscar();
  }, [codigoStatus, dataInicio, dataFim]);

  useUpdateEffect(() => {
    getContasReceber();
  }, [query, pagina]);

  const buscar = async () => {
    if (!dataInicio || !dataInicio.isValid() || !dataFim || !dataFim.isValid()) {
      return;
    }

    let where: EdWhereObject<ContaReceber> = { cancelada: EdNot('S') };

    // QUANDO SELECIONADO PENDENTE BUSCO TODOS EXCETO OS PAGOS, INDEPENDENTE DA DATA
    if (codigoStatus === DUPR_STATUS_EM_ABERTO) {
      where = {
        ...where,
        statusPagamento: EdNot(DUPR_STATUS_PAGO)
      };
    }

    if (codigoStatus === DUPR_STATUS_PAGO) {
      where = {
        ...where,
        statusPagamento: DUPR_STATUS_PAGO, dataVencimento: EdBetween(dataInicio.format('YYYYMMDD'), dataFim.format('YYYYMMDD'))
      };
    }

    if (codigoStatus === DUPR_STATUS_TODOS) {
      where = {
        ...where,
        dataVencimento: EdBetween(dataInicio.format('YYYYMMDD'), dataFim.format('YYYYMMDD'))
      };
    }

    where.tipoDocumento = EdNot('V');
    where.cancelada = " "

    const query: FindManyReq<ContaReceber> = {
      where: where,
      relations: ['cliente', 'empresa'],
      order: { dataVencimento: 'ASC' }
    };

    try {
      setLoading(true);
      setQuery(query);
      const totalLinhas = await contaReceberApi.count(query);
      setTotalLinhas(totalLinhas);
      setPagina(0);
    } catch (error: any) {
      setLoading(false);
      console.log('error', error);
      toast.error(error.data || error.message);
    }
  };

  const getContasReceber = async () => {
    try {
      setLoading(true);
      query.skip = pagina * LINHAS_POR_PAGINA;
      query.take = LINHAS_POR_PAGINA;

      const res = await contaReceberApi.getMany(query);
      console.log(res);
      setContasReceber(res);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      console.log('error', error.data);
      toast.error(error.data || error.message);
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'vencimento',
      headerName: 'Data vencimento',
      width: 130,
      align: 'center',
      editable: false,
      renderCell: (row) => {
        const contaReceber = row.row as DadosListagemContasReceberCliente;
        if (!contaReceber.vencimento) return '';
        return <p>{parseData(contaReceber.vencimento)}</p>;
      }
    },
    {
      field: 'numeroParcelas',
      headerName: 'Parcela',
      width: 100,

      editable: false,
      renderCell: (row) => {
        const contaReceber = row.row as DadosListagemContasReceberCliente;
        if (!contaReceber.parcela) return '';
        return <p>{contaReceber.parcela}º Parcela</p>;
      }
    },
    {
      field: 'valor',
      headerName: '(R$) Valor',
      width: 100,
      editable: false,
      renderCell: (row) => {
        const contaReceber = row.row as DadosListagemContasReceberCliente;

        if (contaReceber.status === DUPR_STATUS_PAGO) {
          return (
            <div>
              <div>R$ {formatarDinheiro(contaReceber.valores.valorJaPago)}</div>
            </div>
          );
        }

        return (
          <div>
            <div>R$ {formatarDinheiro(contaReceber.valores.restante)}</div>
          </div>
        );
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      align: 'center',
      editable: false,
      renderCell: (row) => {
        const contaReceber = row.row as DadosListagemContasReceberCliente;
        return getTipoStatus(contaReceber);
      }
    },
    {
      field: 'boleto',
      headerName: 'Boleto',
      width: 100,
      editable: false,
      align: 'left',
      renderCell: (row) => {
        const contaReceber = row.row as DadosListagemContasReceberCliente;
        return (
          <>
            {contaReceber?.codigoBarrasBoleto !== '' && (
              <abbr title="Copiar código barras do boleto">
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(contaReceber.codigoBarrasBoleto);
                    toast.info('Código de barras copiado!');
                  }}
                >
                  <ViewWeekIcon />
                </IconButton>
              </abbr>
            )}
          </>
        );
      }
    },
  ];

  const pixColumn: GridColDef = {
    field: 'pix',
    headerName: 'Pix',
    width: 50,
    editable: false,
    align: 'center',
    renderCell: (row) => {
      const contaReceber = row.row as DadosListagemContasReceberCliente;
      return (
        <>
          {contaReceber.status !== DUPR_STATUS_PAGO && sessao.empresa?.temIntegracaoPix && (
            <>
              <abbr title="Pagar via PIX">
                <IconButton
                  onClick={() => {
                    setOpenModalQrCode(true);
                    setDadosSelecionados(contaReceber);
                  }}
                >
                  <PixIcon />
                </IconButton>
              </abbr>
              <Divider />
            </>
          )}
        </>
      );
    }
  };

  if (sessao.empresa?.temIntegracaoPix) {
    columns.push(pixColumn);
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid
          item
          xs={12}
          sx={{ display: { xs: 'flex', md: 'none' } }}
          style={{ flexDirection: 'column' }}
        >
          {loading ? (
            <Grid item xs={12}>
              <Typography fontWeight={'bold'} variant="h6">
                Buscando informações ...
              </Typography>
              <LinearProgress />
            </Grid>
          ) : (
            <>
              {contasReceber.length === 0 && <>Nenhum débito encontrado no período.</>}

              {contasReceber.map((contaReceber) => (
                <Paper style={{ margin: '8px 0px', padding: '12px' }} key={contaReceber.fatura}>
                  <Grid container height={'100%'}>
                    <Grid item xs={10} textAlign={'left'}>
                      <Typography fontWeight={'bold'} variant="h6">
                        Vencimento: {parseData(contaReceber.vencimento)}
                      </Typography>
                    </Grid>

                    <Grid item xs={1}>
                      {getTipoStatus(contaReceber)}
                    </Grid>

                    <Grid item xs={6} marginTop={'10px'}>
                      <Typography>
                        <p>{contaReceber.parcela}º Parcela</p>
                      </Typography>
                    </Grid>

                    <Grid item xs={6} marginTop={'10px'}>
                      <Typography>
                        <div>TOTAL</div>
                        <div>R$ {formatarDinheiro(contaReceber.valores.restante)}</div>
                      </Typography>
                    </Grid>

                    <Grid item xs={12} textAlign={'center'}>
                      {contaReceber.status !== DUPR_STATUS_PAGO && (
                        <>
                          {contaReceber.codigoBarrasBoleto && (
                            <abbr title="Copiar código barras do boleto">
                              <ButtonGeneric
                                style={{ width: '300px' }}
                                label={'Copiar codigo barras boleto'}
                                variant="outlined"
                                startIcon={<ViewWeekIcon />}
                                onClick={() => {
                                  copyToClipboard(contaReceber?.codigoBarrasBoleto);
                                  toast.info('Código de barras copiado!');
                                }}
                              />
                            </abbr>
                          )}
                          {sessao.empresa?.temIntegracaoPix && (
                            <>
                              <abbr title="Pagar via PIX">
                                <ButtonGeneric
                                  style={{ width: '300px', marginTop: '10px' }}
                                  label={'Pagar via PIX'}
                                  variant="outlined"
                                  startIcon={<PixIcon />}
                                  onClick={() => {
                                    setOpenModalQrCode(true);
                                    setDadosSelecionados(contaReceber);
                                  }}
                                />
                              </abbr>
                            </>
                          )}
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center'
                            }}
                          ></div>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              ))}
            </>
          )}
        </Grid>

        <div style={{ height: 350, width: '100%' }}>
          <DataGrid
            sx={{ display: { xs: 'none', md: 'flex' } }}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            getRowId={(row) => row.fatura}
            paginationModel={{ page: pagina, pageSize: 10 }}
            getRowHeight={() => 'auto'}
            onPaginationModelChange={(model) => {
              setPagina(model.page);
            }}
            paginationMode="server"
            rowCount={totalLinhas}
            pageSizeOptions={[10]}
            rows={contasReceber}
            columns={columns}
            loading={loading}
          />
        </div>
      </Grid>

      {openModalQrCode && dadosSelecionados && (
        <ModalQrCode
          open={openModalQrCode}
          dados={dadosSelecionados}
          onClose={() => {
            setDadosSelecionados(null);
            setOpenModalQrCode(false);
            buscar();
          }}
        />
      )}
    </Grid>
  );
};

export default ListaContaReceber;
