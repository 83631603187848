import { CfgGeralState } from './redux/cfgGeral/cfgGeralTypes';

export const DUPR_STATUS_EM_ABERTO = '0';
export const DUPR_STATUS_PARCIAL = '1';
export const DUPR_STATUS_PAGO = '2';
export const DUPR_STATUS_TODOS = '';

export const DUPR_STATUS = [
  { codigo: DUPR_STATUS_EM_ABERTO, descricao: 'PENDENTE', cor: '#2196f3' },
  { codigo: DUPR_STATUS_PARCIAL, descricao: 'PAGTO. PARCIAL', cor: 'orange' },
  { codigo: DUPR_STATUS_PAGO, descricao: 'PAGO', cor: '#4caf50' }
];

export const TIPO_DOCUMENTO_NORMAL = ' ';
export const TIPO_DOCUMENTO_BOLETO = 'B';
export const TIPO_DOCUMENTO_CHEQUE = 'C';
export const TIPO_DOCUMENTO_DUPLICATA = 'D';
export const TIPO_DOCUMENTO_VISTA = 'V';
export const TIPOS_DOCUMENTO = [
  { codigo: TIPO_DOCUMENTO_NORMAL, descricao: 'NORMAL' },
  { codigo: TIPO_DOCUMENTO_BOLETO, descricao: 'BOLETO' },
  { codigo: TIPO_DOCUMENTO_CHEQUE, descricao: 'CHEQUE' },
  { codigo: TIPO_DOCUMENTO_DUPLICATA, descricao: 'DUPLICATA' },
  { codigo: TIPO_DOCUMENTO_VISTA, descricao: 'DINHEIRO/CARTÃO' }
];

export const TIPO_PAGAMENTO_TOTAL = 2;
export const TIPO_PAGAMENTO_DUPLICATA_PIX = '02';

export const CODIGO_CONFIRMACAO_VIA_EMAIL = 1;
export const CODIGO_CONFIRMACAO_VIA_WHATSAPP = 2;

export const OPCOES_CODIGO_CONFIRMACAO = [
  CODIGO_CONFIRMACAO_VIA_EMAIL,
  CODIGO_CONFIRMACAO_VIA_WHATSAPP
];

export const URLS_CLIENTES: Record<string, CfgGeralState> = {
  seletro: { urlRemota: 'https://seletro.edsys.com.br:3001' },
  kione: { urlRemota: 'https://at.edsys.com.br:3001' },
  casa505: { urlRemota: 'https://casa505.edsys.com.br:3001' },
  lm: { urlRemota: 'https://lm.edsys.com.br:3001' },
  demo: { urlRemota: 'https://menut1.edsys.com.br:33221' },
  gj: { urlRemota: 'https://jgl.edsys.com.br:3001' }
};

export const STATUS_PEDIDO_EDCLIENTE_EM_ANALISE = '0';
export const STATUS_PEDIDO_EDCLIENTE_APROVADO = '1';
export const STATUS_PEDIDO_EDCLIENTE_CANCELADO = '2';
